import React from "react"
import { MainImage } from "gatsby-plugin-image"
// import { Link } from "gatsby"

import Layout from "../_common/layout/default"
import banner from "./../../static/images/placeholder/barra_cofin_FEDER.png"

const Portugal2020 = ({ location }) => {
	return (
		<Layout location={location} lang="en" title="Design & Ecology in Cement Products">
			<main className="block">
				<article className="block" itemScopeitemType="http://schema.org/Article">
					<header className="overflow-x-hidden pt-lg-4">
						<div className="container">
							<div className="row align-items-end">
								<div className="col-lg-8">
									<h1 className="display-4 ls-n2 lh-1 mt-auto mb-lg-0 visually-hidden sr-only">
										Portugal {" "}
										<span className="fw-bold text-primary"> 2020</span>.
									</h1>
									<MainImage className="my-auto img-fluid mt-3" src={banner} width={645} placeholder="none" alt="banner Portugal 2020" />
								</div>
								<div className="col-lg-4 text-lg-end">
									<p className="text-muted mb-0 lh-copy">Get started and <br className="d-none d-xl-block" /><a href="mailto:info@hidralstone.com" target="_blank" rel="noreferrer noopener" className="text-primary">request</a> a quotation</p>
								</div>
							</div>
						</div>
					</header>
					<section className="block pb-4 pb-xl-5 pt-xl-4" id="pt2020">
						<div className="container">
							<h1 className="visually-hidden sr-only">Project</h1>
							<hr />
							<div className="py-4">
								<div className="row align-items-center gx-5">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Project designation
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											Hidralstone inovação produtiva - Innovative industry of ecological and sustainable cementitious products for the construction industry.
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Project ID
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											ALT20-01-0853-FEDER-072100
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Primary objective
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											Reforçar a competitividade das pequenas e médias empresas
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Region
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											Alentejo, Montemor-o-Novo
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											beneficiary
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											<strong>HIDRALSTONE</strong>
										</p>
									</div>
								</div>
							</div>
							<hr />
							<div className="py-4">
								<div className="row align-items-center gx-5">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Approval date
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											15-01-2021
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Start date
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											01-10-2020
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											End date
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											30-09-2022
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											Total eligible cost
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											1.624.022,00 €
										</p>
									</div>
								</div>
								<div className="row align-items-center gx-5 pt-1">
									<div className="col-12 col-md">
										<div className="small ff-aux fw-semi ls-1 text-primary text-uppercase">
											European Union financial support
										</div>
									</div>
									<div className="col-12 col-md-8 col-xl-9">
										<p className="my-auto fw-book">
											493.402,93 €
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</article>
				{/* add section */}
			</main>
			<section className="block py-5 bg-hs text-white">
				<div className={"container container-base text-center"}>
					<h2 className="lh-1 mb-2 fs-1">Let's talk</h2>
					<p className="o-66">Discover our products and talk with us through our email <u>info@hidralstone.com</u> to make your request.</p>

					<a className="text-inherit fw-demi" href="https://hidralstone.com" target="_blank" rel="noreferrer noopener">- Get Started</a>
				</div>
			</section>
		</Layout>
	)
}

export default Portugal2020
